import {
  SETUP_USER_REQUEST,
  SETUP_USER_SUCCESS,
  SETUP_USER_FAILURE,
  GET_SELECTABLE_PARTNERS_REQUEST,
  GET_SELECTABLE_PARTNERS_SUCCESS,
  GET_SELECTABLE_PARTNERS_FAILURE,
  SUBMIT_SELECTION_REQUEST,
  SUBMIT_SELECTION_SUCCESS,
  SUBMIT_SELECTION_FAILURE,
  CLEAR_SUBMITTED_NOTIFICATIONS,
  GET_CURRENT_STATUS_REQUEST,
  GET_CURRENT_STATUS_SUCCESS,
  GET_CURRENT_STATUS_FAILURE,
  SUBMIT_HAPPINESS_REQUEST,
  SUBMIT_HAPPINESS_SUCCESS,
  SUBMIT_HAPPINESS_FAILURE,
  GET_TEAM_FOR_EMPLOYEE_REQUEST,
  GET_TEAM_FOR_EMPLOYEE_SUCCESS,
  GET_TEAM_FOR_EMPLOYEE_FAILURE,
  ADAPT_SELECTION,
  UPDATE_PARTNER_SORTING
} from './userActionTypes'
import produce from 'immer'
import _ from 'lodash'

const initialState = {
  currentUser: undefined,
  allPartners: undefined,
  currentSelection: undefined,
  userError: undefined,
  userLoading: false,
  partnerError: undefined,
  partnerLoading: false,
  selectionSubmitted: false,
  submittingError: false,
  submittingSuccess: false,
  status: undefined,
  statusError: undefined,
  statusLoading: false,
  submitHappinessError: false,
  currentTeamForEmployee: undefined,
  teamRequestError: undefined,
  teamRequestLoading: false
}

const userActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_USER_REQUEST:
      return {
        ...state,
        userError: undefined,
        currentUser: undefined,
        userLoading: true
      }
    case SETUP_USER_SUCCESS:
      return {
        ...state,
        userError: undefined,
        currentUser: action.payload,
        userLoading: false,
        selectionSubmitted: action.payload ? action.payload.selection !== null : false
      }
    case SETUP_USER_FAILURE:
      return {
        ...state,
        userError: action.payload,
        currentUser: undefined,
        userLoading: false,
        //in case of invalid user => reset all
        allPartners: undefined,
        partnerError: undefined,
        partnerLoading: false,
        selectionSubmitted: false,
        submittingError: false,
        submittingSuccess: false
      }
    case ADAPT_SELECTION:
      return {
        ...state,
        userError: undefined,
        //dont touch currentUser
        userLoading: false,
        selectionSubmitted: false
      }
    case GET_SELECTABLE_PARTNERS_REQUEST:
      return {
        ...state,
        partnerError: undefined,
        allPartners: undefined,
        partnerLoading: true
      }
    case GET_SELECTABLE_PARTNERS_SUCCESS:
      return {
        ...state,
        partnerError: undefined,
        allPartners: _.shuffle(action.payload),
        partnerLoading: false
      }
    case GET_SELECTABLE_PARTNERS_FAILURE:
      return {
        ...state,
        partnerError: action.payload,
        allPartners: undefined,
        partnerLoading: false
      }

    case SUBMIT_SELECTION_REQUEST:
      return {
        ...state,
        currentSelection: undefined,
        submittingError: false,
        selectionSubmitted: false,
        submittingSuccess: false
      }
    case SUBMIT_SELECTION_SUCCESS:
      return {
        ...state,
        currentSelection: action.payload,
        submittingError: false,
        selectionSubmitted: true,
        submittingSuccess: true
      }
    case SUBMIT_SELECTION_FAILURE:
      return {
        ...state,
        currentSelection: undefined,
        submittingError: action.payload,
        selectionSubmitted: false,
        submittingSuccess: false
      }

    case CLEAR_SUBMITTED_NOTIFICATIONS:
      return {
        ...state,
        currentSelection: undefined,
        userError: undefined,
        partnerError: undefined,
        submittingError: false,
        submittingSuccess: false
      }
    case GET_CURRENT_STATUS_REQUEST:
      return {
        ...state,
        //status: undefined,
        statusError: undefined,
        statusLoading: true
      }
    case GET_CURRENT_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload,
        statusError: undefined,
        statusLoading: false
      }
    case GET_CURRENT_STATUS_FAILURE:
      return {
        ...state,
        status: undefined,
        statusError: action.payload,
        statusLoading: false
      }
    case SUBMIT_HAPPINESS_REQUEST:
      return {
        ...state,
        submitHappinessError: false
      }
    case SUBMIT_HAPPINESS_SUCCESS:
      const user = produce(state.currentUser, (draft) => {
        draft.rating = action.payload
      })
      return {
        ...state,
        currentUser: user
      }
    case SUBMIT_HAPPINESS_FAILURE:
      return {
        ...state,
        submitHappinessError: true
      }
    case GET_TEAM_FOR_EMPLOYEE_REQUEST:
      return {
        ...state,
        currentTeamForEmployee: undefined,
        teamRequestError: undefined,
        teamRequestLoading: true
      }
    case GET_TEAM_FOR_EMPLOYEE_SUCCESS:
      return {
        ...state,
        currentTeamForEmployee: action.payload,
        teamRequestError: undefined,
        teamRequestLoading: false
      }
    case GET_TEAM_FOR_EMPLOYEE_FAILURE:
      return {
        ...state,
        currentTeamForEmployee: undefined,
        teamRequestError: action.payload,
        teamRequestLoading: false
      }
    case UPDATE_PARTNER_SORTING:
      return {
        ...state,
        allPartners: action.payload
      }
    default:
      return state
  }
}

export default userActionReducer
