import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Switch, Route, withRouter } from 'react-router-dom'
import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import '../layout/layout.scss'
import './App.scss'
import 'primeflex/primeflex.css'
import { connect } from 'react-redux'
import RatingPage from '../components/Pages/RatingPage'

function App(props) {
  function addClass(element, className) {
    if (element.classList) element.classList.add(className)
    else element.className += ' ' + className
  }

  return (
    <div className="App">
      <div
        className={classNames('layout-wrapper')}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/layout/images/beratung_netz-intro.png'})`
        }}>
        <div>
          <Switch>
            <Route exact path="/" component={RatingPage} />
          </Switch>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    //actions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // initMenu: () => dispatch(initMenu()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
