import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import logger from 'redux-logger'
import rootReducer from './rootReducer'

const middleWare = [logger, thunk]

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleWare)))

export const persistor = persistStore(store)

export default { store, persistor }
