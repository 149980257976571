import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import createEncryptor from 'redux-persist-transform-encrypt'
import storageSession from 'redux-persist/lib/storage/session'
import userActionReducer from './user/userActionReducer'

const encryptor = createEncryptor({
  secretKey: '3P2e9BK6WrYmVh9U'
})

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['user'],
  transforms: [encryptor]
}

const rootReducer = combineReducers({
  user: userActionReducer
})

export default persistReducer(persistConfig, rootReducer)
