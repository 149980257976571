import React, { useRef, forwardRef, useImperativeHandle } from 'react'

function Result(props) {
  const { partner, employees } = props

  const createPartnerSnippet = () => {
    if (partner) {
      return (
        <div className="employee-img-container">
          <img
            src={process.env.PUBLIC_URL + '/assets/img/' + partner.imageUrl}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
          />
          <p style={{ textAlign: 'center' }}>
            <span class="porange">{partner.name} </span>
          </p>
        </div>
      )
    } else {
      return null
    }
  }

  const createEmployeeSnippet = (imgUrl, firstname, lastname) => {
    return (
      <div className="employee-img-container">
        <img
          src={process.env.PUBLIC_URL + '/assets/img/' + imgUrl}
      
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', }}
        />
        <p style={{ textAlign: 'center' }}>
          <div class="pwhite">{firstname} </div>
          <div class="porange">{lastname} </div>
        </p>
      </div>
    )
  }

  return (
    <div className="result">
      <div
        className="card"
        style={{ backgroundColor: 'rgba(10, 10, 10, 0.7)', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
        {createPartnerSnippet()}
        {employees.map((e) => createEmployeeSnippet(e.imageUrl, e.firstname, e.lastname))}
      </div>
    </div>
  )
}

export default Result
