import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getCurrentUser,
  getAllPartners,
  submitVoting,
  clearNotifications,
  getCurrentStatus,
  submitHappiness,
  getTeamForEmployee,
  adaptSelection,
  updatePartnerSorting
} from '../../redux/user/userActions'
import PartnerSelection from '../Components/PartnerSelection'
import Result from '../Components/Result'
import { Growl } from 'primereact/growl'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useInterval } from '../../Helper/useInterval'
import Rating from '@material-ui/lab/Rating'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import Box from '@material-ui/core/Box'
import _ from 'lodash'

function RatingPage(props) {
  const [userId, setUserId] = useState('')
  const [rating, setRating] = useState(3)
  let partnerSelection = useRef(null)
  let growl = useRef(null)

  //init all partners & this user
  useEffect(() => {
    const id = new URLSearchParams(props.location.search).get('id')
    setUserId(id)
    props.getCurrentUser(id)
  }, [])
  useEffect(() => {
    props.getAllPartners()
  }, [])
  useEffect(() => {
    document.body.style.backgroundColor = 'black'
  }, [])

  //call the getResult each 5 seconds
  useInterval(() => {
    if (!props.status || (props.status && props.status.status !== 'PUBLIC')) {
      props.callCurrentSystemStatus()
    }
  }, 3000)

  useInterval(() => {
    if (props.status && props.status.status === 'PUBLIC' && !props.teamResult) {
      props.getTeamForEmployee(userId)
    }
  }, 5000)

  //show errors
  useEffect(() => {
    if (growl.current) {
      if (props.submittingError) {
        growl.current.show({
          severity: 'error',
          summary: 'Oops',
          detail: 'Ihre Auswahl konnte nicht übermittelt werden'
        })
        props.clearNotifications()
      } else if (props.submittingSuccess) {
        growl.current.show({
          severity: 'success',
          summary: 'ERFOLG',
          detail: 'Ihre Auswahl wurde erfolgreich übermittelt'
        })
        props.clearNotifications()
      }
    }
  })

  const updateOrderForPartners = () => {
    const partners = props.partners
    const user = props.user
    if (partners && user && user.selection) {
      var sortedPartners = []
      user.selection.forEach((pId) => {
        const p = _.find(partners, { id: pId })
        sortedPartners.push(p)
      })
      //if there are any changes in order => update
      for (let i = 0; i < partners.length; i++) {
        if (partners[i] !== sortedPartners[i]) {
          props.setPartnersSorted(sortedPartners)
        }
      }
    }
  }

  const handleSubmit = () => {
    const data = partnerSelection.current.getSelection()
    props.submitResult(userId, data)
  }

  const handleSubmitRating = () => {
    growl.current.show({ severity: 'success', summary: 'ERFOLG', detail: 'Ihre Auswahl wurde erfolgreich übermittelt' })
    props.submitHappiness(userId, rating)
  }

  const status = props.status && props.status.status

  const showUser = () => {
    return props.user ? (
      <div>
        <p style={{ textAlign: 'center' }}>
          <span class="porange">{props.user.firstname} </span>
          <span class="pwhite">{props.user.lastname} </span>
        </p>
        <img
          src={process.env.PUBLIC_URL + '/assets/img/' + props.user.imageUrl}
          class="circularpic"
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: "20px"}}
        />
      </div>
    ) : (
      ''
    )
  }

  const showPartnerSelection = () => {
    return props.partners ? (
      <div>
        <PartnerSelection items={props.partners} ref={partnerSelection} />
      </div>
    ) : (
      ''
    )
  }

  const showSuccessPage = () => {
    return (
      <div>
        <p style={{ textAlign: 'center' }}>
          <span class="porange">AUSWAHL </span>
          <span class="pwhite">GESENDET </span>
          <h3 style={{ color: '#FFFFFF', textAlign: 'center' }}>Ihre Auswahl wurde erfolgreich gespeichert.</h3>{' '}
          {status === 'WAITING' || status === undefined ? (
            <p style={{ color: '#FFFFFF', textAlign: 'center' }}>Sobald ein Ergebnis vorliegt wird es hier sichtbar.</p>
          ) : (
            <p style={{ color: '#FFFFFF', textAlign: 'center' }}>Die Berechnung wurde gestartet.</p>
          )}
          <img
            src={process.env.PUBLIC_URL + '/assets/layout/images/succ.png'}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '30%', marginTop: '50px' }}
          />
          <ProgressSpinner style={{ width: '50px', height: '50px', marginTop: '50px', marginBottom: '50px' }} />
          {status === 'WAITING' || status === undefined ? (
            <p>
              <button
                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                onClick={props.voteAgain}
                className="senden">
                Meine Wahl anpassen
              </button>
            </p>
          ) : (
            ''
          )}
        </p>
      </div>
    )
  }

  const showUserNotFoundPage = () => {
    return (
      <div>
        <p style={{ textAlign: 'center' }}>
          <span class="porange">KEIN </span>
          <span class="pwhite">Nutzer </span>
          <h3 style={{ color: '#FFFFFF', textAlign: 'center' }}>Dieser Nutzer konnte leider nicht gefunden werden.</h3>
          <img
            src={process.env.PUBLIC_URL + '/assets/layout/images/error.png'}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '30%' }}
          />
        </p>
      </div>
    )
  }

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon />,
      label: 'Very Dissatisfied'
    },
    2: {
      icon: <SentimentDissatisfiedIcon />,
      label: 'Dissatisfied'
    },
    3: {
      icon: <SentimentSatisfiedIcon />,
      label: 'Neutral'
    },
    4: {
      icon: <SentimentSatisfiedAltIcon />,
      label: 'Satisfied'
    },
    5: {
      icon: <SentimentVerySatisfiedIcon />,
      label: 'Very Satisfied'
    }
  }

  function IconContainer(props) {
    const { value, ...other } = props
    return <span {...other}>{customIcons[value].icon}</span>
  }

  const injectRating = () => {
    if (props.user && props.user.rating > -1) {
      return null
    } else {
      return (
        <div class="card" style={{ backgroundColor: 'rgba(100, 100, 100, 0.6)' }}>
          <Box component="fieldset" borderColor="transparent">
            <p class="pwhite pcenter" style={{ margin: '0px', paddingBottom: '10px' }}>
              Wie zufrieden bist du mit dem Ergebnis?
            </p>
            <div style={{ textAlign: 'center' }}>
              <Rating
                name="customized-icons"
                defaultValue={rating}
                getLabelText={(value) => customIcons[value].label}
                IconContainerComponent={IconContainer}
                onChange={(event, newValue) => {
                  setRating(newValue)
                }}
                size="large"
              />
            </div>
          </Box>
          <div>
            <button
              style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              onClick={handleSubmitRating}
              className="senden">
              ABSENDEN
            </button>
          </div>
        </div>
      )
    }
  }

  const showResultPage = () => {
    return (
      <div className="rating-page-result">
        <div style={{  overflow: 'auto' }}>
        <p style={{ textAlign: 'center' }}>
          <span class="porange">DEIN </span>
          <span class="pwhite">TEAM</span>
        </p>
        {props.teamResult ? (
          <div>
            {injectRating()}
            <Result partner={props.teamResult.partner} employees={props.teamResult.employees} />
          </div>
        ) : (
          ''
        )}
        </div>
      </div>
    )
  }

  const showSelectionPage = () => {
    updateOrderForPartners()
    return (
      <div>
        <div>{showUser()}</div>
        <p/>
        <div>{showPartnerSelection()}</div>
        <p>
          <button
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
            onClick={handleSubmit}
            className="senden">
            ABSENDEN
          </button>
        </p>
      </div>
    )
  }

  return (
    <div
      style={{
        width: '100%'
      }}>
      <Growl ref={growl} />
      {props.userNotFound
        ? showUserNotFoundPage()
        : !props.selectionSubmitted && (status === 'WAITING' || status === undefined)
        ? showSelectionPage()
        : status === 'PUBLIC'
        ? showResultPage()
        : showSuccessPage()}
      
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser,
    partners: state.user.allPartners,
    allPartners: state.user.allPartners,
    selectionSubmitted: state.user.selectionSubmitted,
    userNotFound: state.user.userError,
    currentSelection: state.user.currentSelection,
    submittingError: state.user.submittingError,
    submittingSuccess: state.user.submittingSuccess,
    status: state.user.status,
    teamResult: state.user.currentTeamForEmployee
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentUser: (userId) => dispatch(getCurrentUser(userId)),
    getAllPartners: () => dispatch(getAllPartners()),
    submitResult: (employeeId, partnerList) => dispatch(submitVoting(employeeId, partnerList)),
    clearNotifications: () => dispatch(clearNotifications()),
    callCurrentSystemStatus: () => dispatch(getCurrentStatus()),
    submitHappiness: (employeeId, rating) => dispatch(submitHappiness(employeeId, rating)),
    getTeamForEmployee: (employeeId) => dispatch(getTeamForEmployee(employeeId)),
    voteAgain: () => dispatch(adaptSelection()),
    setPartnersSorted: (partners) => dispatch(updatePartnerSorting(partners))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingPage)
