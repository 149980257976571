export const SETUP_USER_REQUEST = 'SETUP_USER_REQUEST'
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS'
export const SETUP_USER_FAILURE = 'SETUP_USER_FAILURE'

export const GET_SELECTABLE_PARTNERS_REQUEST = 'GET_SELECTABLE_PARTNERS_REQUEST'
export const GET_SELECTABLE_PARTNERS_SUCCESS = 'GET_SELECTABLE_PARTNERS_SUCCESS'
export const GET_SELECTABLE_PARTNERS_FAILURE = 'GET_SELECTABLE_PARTNERS_FAILURE'

export const SUBMIT_SELECTION_REQUEST = 'SUBMIT_SELECTION_REQUEST'
export const SUBMIT_SELECTION_SUCCESS = 'SUBMIT_SELECTION_SUCCESS'
export const SUBMIT_SELECTION_FAILURE = 'SUBMIT_SELECTION_FAILURE'

export const CLEAR_SUBMITTED_NOTIFICATIONS = 'CLEAR_SUBMITTED_NOTIFICATIONS'
export const ADAPT_SELECTION = 'ADAPT_SELECTION'
export const UPDATE_PARTNER_SORTING = 'UPDATE_PARTNER_SORTING'

export const GET_CURRENT_STATUS_REQUEST = 'GET_CURRENT_STATUS_REQUEST'
export const GET_CURRENT_STATUS_SUCCESS = 'GET_CURRENT_STATUS_SUCCESS'
export const GET_CURRENT_STATUS_FAILURE = 'GET_CURRENT_STATUS_FAILURE'

export const GET_TEAM_FOR_EMPLOYEE_REQUEST = 'GET_TEAM_FOR_EMPLOYEE_REQUEST'
export const GET_TEAM_FOR_EMPLOYEE_SUCCESS = 'GET_TEAM_FOR_EMPLOYEE_SUCCESS'
export const GET_TEAM_FOR_EMPLOYEE_FAILURE = 'GET_TEAM_FOR_EMPLOYEE_FAILURE'

export const SUBMIT_HAPPINESS_REQUEST = 'SUBMIT_HAPPINESS_REQUEST'
export const SUBMIT_HAPPINESS_SUCCESS = 'SUBMIT_HAPPINESS_SUCCESS'
export const SUBMIT_HAPPINESS_FAILURE = 'SUBMIT_HAPPINESS_FAILURE'
