import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import clamp from 'lodash/clamp'
import swap from 'lodash-move'
import { useGesture } from 'react-use-gesture'
import { useSprings, animated, interpolate } from 'react-spring'
import '../Pages/styles.css'

// Returns fitting styles for dragged/idle items
const fn = (order, down, originalIndex, curIndex, y) => (index) =>
  down && index === originalIndex
    ? { y: curIndex * 60 + y, scale: 1.1, zIndex: '1', shadow: 15, immediate: (n) => n === 'y' || n === 'zIndex' }
    : { y: order.indexOf(index) * 60, scale: 1, zIndex: '0', shadow: 1, immediate: false }

const PartnerSelection = forwardRef((props, ref) => {
  const [toggle, setToggle] = useState(false)
  const { items } = props
  const order = useRef(items.map((_, index) => index)) // Store indicies as a local ref, this represents the item order
  const [springs, setSprings] = useSprings(items.length, fn(order.current)) // Create springs, each corresponds to an item, controlling its transform, scale, etc.
  const bind = useGesture(({ args: [originalIndex], down, delta: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex)
    const curRow = clamp(Math.round((curIndex * 80 + y) / 100), 0, items.length - 1)
    const newOrder = swap(order.current, curIndex, curRow)
    setSprings(fn(newOrder, down, originalIndex, curIndex, y)) // Feed springs new style data, they'll animate the view without causing a single render
    if (!down) {
      order.current = newOrder
      setToggle(!toggle)
    }
  })

  useImperativeHandle(ref, () => ({
    getSelection
  }))

  const getSelection = () => {
    const answer = []
    order.current.forEach((entry) => {
      answer.push(items[entry].id)
    })
    return answer
  }

  function calculateColor(pos) {
    let count = 0
    for (var i = 0; i < order.current.length; i++) {
      if (order.current[i] === pos) {
        count = i
      }
    }
    if (count < 3) {
      return 'green'
    } else if (count < 7) {
      return 'orange'
    } else {
      return 'red'
    }
  }

  return (
    <div className="content" style={{ height: items.length * 60 }}>
      {springs.map(({ zIndex, shadow, y, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          className="partner"
          style={{
            zIndex,
            boxShadow: shadow.interpolate((s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
            transform: interpolate([y, scale], (y, s) => `translate3d(0,${y}px,0) scale(${s})`),
            backgroundColor: calculateColor(i)
          }}
          children={items[i].name}
        />
      ))}
    </div>
  )
})

export default PartnerSelection
