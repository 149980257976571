import {
  SETUP_USER_REQUEST,
  SETUP_USER_SUCCESS,
  SETUP_USER_FAILURE,
  GET_SELECTABLE_PARTNERS_REQUEST,
  GET_SELECTABLE_PARTNERS_SUCCESS,
  GET_SELECTABLE_PARTNERS_FAILURE,
  SUBMIT_SELECTION_REQUEST,
  SUBMIT_SELECTION_SUCCESS,
  SUBMIT_SELECTION_FAILURE,
  CLEAR_SUBMITTED_NOTIFICATIONS,
  GET_CURRENT_STATUS_REQUEST,
  GET_CURRENT_STATUS_SUCCESS,
  GET_CURRENT_STATUS_FAILURE,
  SUBMIT_HAPPINESS_REQUEST,
  SUBMIT_HAPPINESS_SUCCESS,
  SUBMIT_HAPPINESS_FAILURE,
  GET_TEAM_FOR_EMPLOYEE_REQUEST,
  GET_TEAM_FOR_EMPLOYEE_SUCCESS,
  GET_TEAM_FOR_EMPLOYEE_FAILURE,
  ADAPT_SELECTION,
  UPDATE_PARTNER_SORTING
} from './userActionTypes'
import axios from 'axios'
import { SECRET, URL_TO_API } from '../../Helper/constants'

export const clearNotifications = () => {
  return {
    type: CLEAR_SUBMITTED_NOTIFICATIONS
  }
}

export const adaptSelection = () => {
  return {
    type: ADAPT_SELECTION
  }
}

export const updatePartnerSorting = (sortedPartners) => {
  return {
    type: UPDATE_PARTNER_SORTING,
    payload: sortedPartners
  }
}

export const readUserRequest = () => {
  return {
    type: SETUP_USER_REQUEST
  }
}
export const readUserSuccess = (user) => {
  return {
    type: SETUP_USER_SUCCESS,
    payload: user
  }
}
export const readUserFailure = (error) => {
  return {
    type: SETUP_USER_FAILURE,
    payload: error
  }
}
export const readAllPartnerRequest = () => {
  return {
    type: GET_SELECTABLE_PARTNERS_REQUEST
  }
}
export const readAllPartnerSuccess = (partners) => {
  return {
    type: GET_SELECTABLE_PARTNERS_SUCCESS,
    payload: partners
  }
}
export const readAllPartnerFailure = (error) => {
  return {
    type: GET_SELECTABLE_PARTNERS_FAILURE,
    payload: error
  }
}
export const submitSelectionRequest = () => {
  return {
    type: SUBMIT_SELECTION_REQUEST
  }
}
export const submitSelectionSuccess = (selection) => {
  return {
    type: SUBMIT_SELECTION_SUCCESS,
    payload: selection
  }
}
export const submitSelectionFailure = (error) => {
  return {
    type: SUBMIT_SELECTION_FAILURE,
    payload: error
  }
}
export const getCurrentStatusRequest = () => {
  return {
    type: GET_CURRENT_STATUS_REQUEST
  }
}
export const getCurrentStatusSuccess = (status) => {
  return {
    type: GET_CURRENT_STATUS_SUCCESS,
    payload: status
  }
}
export const getCurrentStatusFailure = (error) => {
  return {
    type: GET_CURRENT_STATUS_FAILURE,
    payload: error
  }
}
export const submitHappinessRequest = () => {
  return {
    type: SUBMIT_HAPPINESS_REQUEST
  }
}
export const submitHappinessSuccess = (value) => {
  return {
    type: SUBMIT_HAPPINESS_SUCCESS,
    payload: value
  }
}
export const submitHappinessFailure = (error) => {
  return {
    type: SUBMIT_HAPPINESS_FAILURE,
    payload: error
  }
}
export const getTeamForEmployeeRequest = () => {
  return {
    type: GET_TEAM_FOR_EMPLOYEE_REQUEST
  }
}
export const getTeamForEmployeeSuccess = (result) => {
  return {
    type: GET_TEAM_FOR_EMPLOYEE_SUCCESS,
    payload: result
  }
}
export const getTeamForEmployeeFailure = (error) => {
  return {
    type: GET_TEAM_FOR_EMPLOYEE_FAILURE,
    payload: error
  }
}

//FUNCTIONS with side effects
export const getCurrentUser = (userId) => {
  return (dispatch) => {
    dispatch(readUserRequest)
    axios({
      method: 'post',
      baseURL: URL_TO_API,
      url: '/employee/id',
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      data: { id: userId }
    })
      .then((response) => {
        const user = response.data
        dispatch(readUserSuccess(user))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(readUserFailure(errorMsg))
      })
  }
}

export const getAllPartners = () => {
  return (dispatch) => {
    dispatch(readAllPartnerRequest)
    axios({
      method: 'get',
      baseURL: URL_TO_API,
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      url: '/partners'
    })
      .then((response) => {
        const partners = response.data.partners
        dispatch(readAllPartnerSuccess(partners))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(readAllPartnerFailure(errorMsg))
      })
  }
}

export const submitVoting = (employeeId, partnerList) => {
  return (dispatch) => {
    dispatch(submitSelectionRequest)
    axios({
      method: 'post',
      baseURL: URL_TO_API,
      url: '/voting',
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      data: { employeeId: employeeId, orderedSelectionIds: partnerList }
    })
      .then((response) => {
        //if statuscode ok then dispatch
        dispatch(submitSelectionSuccess(partnerList))
        //request updated user
        dispatch(getCurrentUser(employeeId))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(submitSelectionFailure(errorMsg))
      })
  }
}

export const getCurrentStatus = () => {
  return (dispatch) => {
    dispatch(getCurrentStatusRequest())
    axios({
      method: 'get',
      baseURL: URL_TO_API,
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      url: '/status'
    })
      .then((response) => {
        const status = response.data
        dispatch(getCurrentStatusSuccess(status))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(getCurrentStatusFailure(errorMsg))
      })
  }
}

export const submitHappiness = (employeeId, value) => {
  return (dispatch) => {
    dispatch(submitHappinessFailure)
    axios({
      method: 'post',
      baseURL: URL_TO_API,
      url: '/voting/happy',
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      data: { employeeId: employeeId, rating: value }
    })
      .then(() => {
        //if statuscode ok then dispatch
        dispatch(submitHappinessSuccess(value))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(submitHappinessFailure(errorMsg))
      })
  }
}

export const getTeamForEmployee = (employeeId) => {
  return (dispatch) => {
    dispatch(getTeamForEmployeeRequest())
    axios({
      method: 'post',
      baseURL: URL_TO_API,
      headers: {
        'Authorization': 'Bearer '+SECRET
      },
      url: '/result/userId',
      data: { id: employeeId }
    })
      .then((response) => {
        const result = response.data
        dispatch(getTeamForEmployeeSuccess(result))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(getTeamForEmployeeFailure(errorMsg))
      })
  }
}
